import ApplicationController from "./application_controller";

export default class extends ApplicationController {
  static targets = ["initialAmountInput", "minimumInitialAmountInput", "maximumInitialAmountInput"];

  connect() {
    super.connect();
    this.setInitialOfferRange();
    this.setGreaterThanValidationForMinimumAndMaximumAmount();
  }

  setInitialOfferRange() {
    const initialAmountVal = this.initialAmountInputTarget.value;

    // If initial amount is empty, reset the minimum and maximum amount
    if (!initialAmountVal) {
      this.minimumInitialAmountInputTarget.value = "";
      this.maximumInitialAmountInputTarget.value = "";
      return;
    }

    const initialAmount = this._numericValue(initialAmountVal);

    // Set minimum amount
    this.minimumInitialAmountInputTarget.value = Math.round(
      initialAmount * 0.85
    ).toLocaleString("en-US");

    this.maximumInitialAmountInputTarget.value = Math.round(
      initialAmount * 1.15
    ).toLocaleString("en-US");
  }

  setGreaterThanValidationForMinimumAndMaximumAmount() {
    const initialAmount = this._numericValue(
      this.initialAmountInputTarget.value
    );

    // Set data-less-than value for minimum amount
    if (!initialAmount) {
      this.minimumInitialAmountInputTarget.dataset.lessThan = 0;
    } else {
      this.minimumInitialAmountInputTarget.dataset.lessThan = initialAmount;
    }

    // Set data-greater-than value for maximum amount
    if (!initialAmount) {
      this.maximumInitialAmountInputTarget.dataset.greaterThan = 0;
    } else {
      this.maximumInitialAmountInputTarget.dataset.greaterThan = initialAmount;
    }
  }

  _numericValue(value) {
    return parseInt(value.replace(/[^0-9]/g, ""));
  }
}
