import ApplicationController from "../../../../javascript/controllers/application_controller";
import Cleave from "cleave.js";

export default class extends ApplicationController {
  static targets = ["moneyInput"]

  connect() {
    new Cleave(this.moneyInputTarget, {
      delimiter: ',',
      numeral: true,
      numeralThousandsGroupStyle: "thousand",
      swapHiddenInput: true,
      numeralDecimalScale: 0,
      numeralIntegerScale: 9
    });
    if (!this.moneyInputTarget?.pattern) {!this.moneyInputTarget.removeAttribute("pattern")}
    //! Uncomment if you need to generate HTML ID selectors for the Cleave inputs
    // Generate IDs
    // const hiddenInput = this.element.querySelector("input[type='hidden']");
    // const storeId = hiddenInput.id
    // this.element.querySelector("input[type='text']").id = storeId;
    // hiddenInput.id = `${storeId}-hidden`;
  }
}
